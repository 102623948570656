/*html{*/
/*  font-size: 10px;*/
/*}*/

body {
  margin: 0;
  font-family: "Hellix", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e0529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;

  min-height: 0;
  min-width: 0;
}

.swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;
}

.swiper-slide {
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.SnackbarItem-message{
  font-size: 2rem;
}